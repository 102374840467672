<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-mapaver"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Ubicacion Novedad</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- <div class="form-group col-md-12">
              <label>Buscar Ubicación:</label>
              <gmap-autocomplete
                class="form-control form-control-sm"
                @place_changed="setPlace"
                :options="{
                  fields: [
                    'geometry',
                    'formatted_address',
                    'address_components',
                  ],
                }"
              >
                >
              </gmap-autocomplete>
            </div> -->
            <div class="row">
              <div class="col-md-8">
                <gmap-map
                  :center="center"
                  :zoom="zoom"
                  :options="{ streetViewControl: false }"
                  style="width: 100%; height: 650px"
                  @click="addMarkers"
                >
                  <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :icon="m.icon"
                    :draggable="true"
                    @dragend="addMarkers"
                  ></gmap-marker>
                  <!--  -->
                </gmap-map>
              </div>
              <div class="col-md-4">
                <table class="table table-sm table-striped table-bordered">
                  <thead class="thead-dark">
                    <tr>
                      <th colspan="3" class="text-center">Viaje</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">
                        <span class="badge bg-olive">Latitud</span>
                      </td>
                      <td>
                        {{ form_novedad.lat }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center">
                        <span class="badge bg-pink">Longitud</span>
                      </td>
                      <td>
                        {{ form_novedad.long }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-if="$store.getters.can('tep.turnos.finalizar')"
              @click="save()"
            >
              Finalizar
            </button>
          </div>
        </div>
        <div class="modal-footer justify-content-between"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TifNovedadesMapa",
  components: {},
  data() {
    return {
      flag: null,
      form_novedad: {
        vehiculo: {
          ultimo_registro: {
            gps: {},
          },
        },
        lat: null,
        long: null,
      },
      map: null,
      destino: {},
      coordinates: null,
      center: { lat: 4.651371, lng: -74.071509 },
      zoom: 10,
      markers: [],
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    async llenar_modal_mapa(novedad) {
      if (novedad) {
        this.flag = true;
        this.form_novedad = {
          id: novedad.id,
          lat: novedad.lat_ini,
          long: novedad.lon_ini,
        };
        this.getMapa();
      }
    },
    limpiarMapa() {
      this.markers = [];
    },
    getMapa() {
      const destino = {
        lat: parseFloat(this.form_novedad.lat),
        lng: parseFloat(this.form_novedad.long),
      };
      this.center = destino;
      this.zoom = 10;
      this.markers.push({
        position: destino,
        icon: "/img/icon_punto_g_ok.png",
        center: {
          destino,
        },
      });
    },
    async addMarkers(event) {
      if (!this.flag) {
        await this.limpiarMapa();
        // Datos del origen
        this.form_novedad.lat = parseFloat(event.latLng.lat());
        this.form_novedad.long = parseFloat(event.latLng.lng());
        const destino = {
          lat: parseFloat(event.latLng.lat()),
          lng: parseFloat(event.latLng.lng()),
        };
        await this.markers.push({
          position: destino,
          icon: "/img/icon_punto_g_ok.png",
        });
      }
    },
    //     save() {
    //       axios({
    //         method: "PUT",
    //         url: "/api/tep/turnoFinalizar",
    //         data: this.form_novedad,
    //       })
    //         .then((response) => {
    //           this.form_estado = {};
    //           this.$refs.closeModal.click();
    //           this.$swal({
    //             icon: "success",
    //             title: response.data.msg,
    //             toast: true,
    //             position: "top-end",
    //             showConfirmButton: false,
    //             timer: 3000,
    //             timerProgressBar: true,
    //           });
    //         })
    //         .catch((e) => {
    //           this.cargando = false;
    //           this.$swal({
    //             icon: "error",
    //             title: "Ocurrio un error, vuelva a intentarlo..." + e,
    //             toast: true,
    //             position: "top-end",
    //             showConfirmButton: false,
    //             timer: 3000,
    //             timerProgressBar: true,
    //           });
    //         });
    //       this.$parent.getIndex();
    //     },
    // setPlace(place) {
    //   const longitud = this.getCentro(place.geometry.viewport.Qa);
    //   const latitud = this.getCentro(place.geometry.viewport.zb);
    //   const destino = {
    //     lat: parseFloat(latitud),
    //     lng: parseFloat(longitud),
    //   };
    //   this.center = destino;
    //   this.form_novedad.lat = parseFloat(latitud);
    //   this.form_novedad.lo;
    //   this.markers.push({
    //     position: destino,
    //     icon: "/img/icon_punto_g_ok.png",
    //   });
    // },
    // getCentro(coordenadas) {
    //   return (coordenadas.g + coordenadas.h) / 2;
    // },
  },
};
</script>

<style>
.pac-container {
  z-index: 10000;
}
</style
>

